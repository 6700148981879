<template>
  <ui-text
    class="number-input"
    type="number"
    :value="value"
    @input="emitNormalized"
    :is-disabled="isDisabled"
    :is-error="Number(value) < Number(min)"
    @focus="isFocused = true"
    @blur="isFocused = false"
    :min="isFocused ? 0 : min"
    :max="max"
    v-bind="$attrs"
  >
    <template v-if="Number(step)">
      <button
        class="number-input__ui-text-btn"
        type="button"
        slot="dock-left"
        :disabled="isMinusDisabled || isDisabled"
        @click="doStep(value, -step)"
      >
        <ui-icon
          class="number-input__ui-text-btn-ico"
          icon="minus"
        />
      </button>

      <button
        class="number-input__ui-text-btn"
        type="button"
        slot="dock-right"
        :disabled="isPlusDisabled || isDisabled"
        @click="doStep(value, +step)"
      >
        <ui-icon
          class="number-input__ui-text-btn-ico"
          icon="plus"
        />
      </button>
    </template>
  </ui-text>
</template>

<script>
import { UiText, UiIcon } from '@shelf.network/ui-kit'

export default {
  name: 'number-input',

  components: {
    UiText,
    UiIcon,
  },

  props: {
    value: {
      type: [String, Number],
      default: '0',
    },

    isDisabled: {
      type: Boolean,
      default: false,
    },

    step: {
      type: [String, Number],
      default: '0',
    },

    min: {
      type: [String, Number],
      default: '0',
    },

    max: {
      type: [String, Number],
      default: Number.MAX_SAFE_INTEGER,
    },
  },

  data () {
    return {
      isFocused: false,
    }
  },

  computed: {
    isMinusDisabled () {
      return (this.value - this.step) < this.min
    },

    isPlusDisabled () {
      return (Number(this.value) + Number(this.step)) > this.max
    },
  },

  methods: {
    emitNormalized (value, a) {
      this.$emit('input', this.normalize(value, this.min, this.max))
    },

    doStep (value, amount) {
      const newValue = Number(value) + amount // can be negative
      this.emitNormalized(this.roundStep(newValue, amount))
    },

    roundStep (value, step) {
      return step * Math.round(value / step)
    },

    normalize (value, min, max) {
      let newValue = Number(value)
      min = Number(min)
      max = Number(max)

      if (newValue < min) {
        newValue = min
      }

      if (newValue > max) {
        newValue = max
      }

      return newValue
    },
  },
}
</script>

<style lang="scss" scoped>
.number-input {
  line-height: 2.75em;

  /deep/ .ui-text__input[fill][look] {
    background: rgba(240, 242, 245, 0.4);
    border-radius: 1em;
    border-color: $color-grey;
    text-align: center;
    color: $color-dark;
  }

  /deep/ .ui-text__dock {
    width: 2.75em;
    height: 2.75em;
    line-height: 2.6em;
  }

  &__ui-text-btn {
    border: none;
    background: none;
    color: $color-dark-grey;
    vertical-align: middle;
    width: 100%;
    height: 100%;
    padding-bottom: 0.2em;

    &:disabled {
      color: rgba($color-dark-grey, 0.3);
      cursor: not-allowed;
    }
  }

  &__ui-text-btn-ico {
    display: block;
    font-size: 1.35em;
  }
}
</style>
