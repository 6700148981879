<template>
  <ui-dropdown
    class="btn-dropdown"
    :class="{
      'btn-dropdown_disabled': isDisabled,
    }"
    ref="dropdownComponent"
    :is-disabled="isReadOnly"
    @open="(isOpen = true) & $emit('open')"
    @close="(isOpen = false) & $emit('close')"
    bubble
  >
    <button
      class="btn-dropdown__toggle"
      :class="{ 'btn-dropdown__toggle_open': isOpen }"
      :disabled="isDisabled || isReadOnly"
    >
      <span class="btn-dropdown__toggle-txt">
        <slot name="btn-txt" />
      </span>

      <template v-if="!isReadOnly && showIcon">
        <ui-icon
          class="btn-dropdown__toggle-arrow"
          icon="arrow-down"
        />
      </template>
    </button>

    <template v-if="!isReadOnly">
      <div
        class="btn-dropdown__content"
        slot="content"
      >
        <template v-if="hasSlot('content-title')">
          <p class="btn-dropdown__content-title">
            <slot name="content-title" />
          </p>
        </template>

        <div class="btn-dropdown__content-main">
          <slot name="content-main" />
        </div>

        <template v-if="hasSlot('content-action')">
          <div class="btn-dropdown__content-actions">
            <slot name="content-action" />
          </div>
        </template>
      </div>
    </template>
  </ui-dropdown>
</template>

<script>
import { UiIcon, UiDropdown } from '@shelf.network/ui-kit'

export default {
  name: 'btn-dropdown',
  components: {
    UiIcon,
    UiDropdown,
  },

  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },

    isReadOnly: {
      type: Boolean,
      default: false,
    },

    showIcon: {
      type: Boolean,
      default: true,
    }
  },

  data () {
    return {
      isOpen: false,
    }
  },

  methods: {
    // FOR OUTSIDE USE
    close () {
      this.$refs.dropdownComponent.$emit('close-dropdown')
    },

    hasSlot (slot) {
      return !!this.$slots[slot]
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-dropdown {
  &_disabled {
    cursor: not-allowed;

    & > .btn-dropdown__toggle,
    /deep/ .ui-dropdown__trigger {
      pointer-events: none;
    }
  }

  /* stylelint-disable no-descending-specificity */
  &__toggle {
    color: inherit;
    position: relative;
    border: none;
    background: none;
    line-height: 1.5;

    &-arrow {
      color: $color-dark;
      margin-left: 0.4em;
      display: inline-block;
      transform: scale(1.2);
      padding-right: 0.2em;
      transition: all 180ms;
    }

    &_open > &-arrow {
      transform: scale(1.2) rotateX(180deg);
      perspective: 5px;
    }

    &:disabled {
      cursor: text;
      user-select: text;
    }
  }

  &__content {
    width: 100vw;
    max-width: 17em;

    &-title {
      margin-bottom: 2em;
    }

    &-actions {
      margin-top: 1.4em;
      display: grid;
      grid-auto-flow: column;
      grid-gap: 0.5em;
      align-items: stretch;
    }
  }

  /deep/ .ui-dropdown__content {
    border-radius: 1em;
    padding: 1.4em 1.4em 2.1em;
  }
}
</style>
